import styled from 'styled-components';
import { device } from '../../styles/Responsive';
import { titleH2, titleH3 } from '../../styles/TextSizes';

export const WeddingContainer = styled.div`
  width: 100%;
  min-height: 400px;
  margin-bottom: 75px;
  display: table;
  cursor: pointer;
  transition: box-shadow 0.5s linear;
  position: relative;
  
  &:hover {
    box-shadow: 8px 0 12px -4px ${p => p.theme.secondaryCTA}, -8px 0 12px -4px ${p => p.theme.secondaryCTA};

    & .view_more {
      background-color: #ECE8E2;

      & div {
        color: rgba(0, 0, 0, 0.65);
      }

      & svg {
        & #arrowright {
          fill: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
`;

export const DateColumn = styled.div`
  background-color: ${p => p.theme.secondaryColor};
  padding: 20px 10px;
  height: 100%;
  display: table-cell;
  width: 15%;

  @media ${device.tablet} {
    padding: 10px 5px;
  }
`;

export const MainColumn = styled.div`
  background-color: ${p => p.theme.thirdColor};
  padding: 20px;
  height: 100%;
  display: table-cell;
  width: ${p => p.hasDate ? '85%' : '100%'};

  @media ${device.tablet} {
    padding: 10px;
  }
`;

export const Day = styled(titleH2)`
  font-weight: 400;
  opacity: 0.2;
`;

export const Month = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.2;

  @media ${device.tablet} {
    font-size: 15px;
  }
`;

export const Year = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.2;

  @media ${device.tablet} {
    font-size: 15px;
  }
`;

export const QuestionContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 10px;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

export const Question = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.3;
  padding-right: 10px;
  text-align: left;
  flex-shrink: 0;

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const AnswerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Answer = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  max-height: 81px;
  overflow: hidden;

  @media ${device.tablet} {
    font-size: 15px;
  }
`;

export const QuestionColorsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 10px;
`;

export const ColorsContainer = styled.div`
  width: 100%;
  padding-top: 20px;
`;

export const ColorsRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0px -15px;

  @media ${device.desktop} {
    margin: 0px -10px;
  }
`;

export const Color = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
  padding-bottom: 20px;
  width: 14.28%;

  @media ${device.desktop} {
    padding: 0px 10px;
    padding-bottom: 20px;
  }

  @media ${device.laptopS} {
    width: 20%;
  }

  @media ${device.tabletS} {
    width: 25%;
  }

  @media ${device.mobileL} {
    width: 33.333%;
  }

  @media ${device.mobileM} {
    width: 50%;
  }

  @media ${device.mobileS} {
    width: 100%;
  }
`;

export const ColorSquare = styled.div`
  border: 1px solid ${p => p.theme.primaryColor};
  width: 40px;
  height: 40px;
  padding: 4px;
`;

export const ColorBg = styled.div`
  background-color: ${p => p.bgColor};
  width: 100%;
  height: 100%;
  position: relative;
`;

export const Plus = styled.div`
  color: #DFCBB2;
  font-size: 38px;
  font-weight: 400;
  line-height: 46px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const ColorName = styled.div`
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 15px;
  height: 48px;
  overflow: hidden;

  @media ${device.tablet} {
    font-size: 15px;
  }
`;

export const ImageBg = styled.div`
  max-width: 100%;
`;

/***************************************** DETAIL *****************************************/

export const WeddingDetailContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 100px;
  z-index: 1000;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: #D8D8D8;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    z-index: 999;
  }

  @media ${device.desktop} {
    padding: 50px;
  }

  @media ${device.tablet} {
    padding: 20px;
  }

  @media ${device.mobileL} {
    padding: 10px;
  }
`;

export const WeddingDetailContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const WeddingDetail = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  z-index: 1001;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: auto;
`;

export const DateColumnDetail = styled.div`
  background-color: ${p => p.theme.secondaryColor};
  padding: 60px 10px;
  flex-basis: 15%;

  @media ${device.tablet} {
    padding: 60px 5px;
  }
`;

export const MainColumnDetail = styled.div`
  background-color: #ffffff;
  margin: 60px 20px;
  height: 100%;
  flex-basis: ${p => p.hasDate ? '85%' : '100%'};

  @media ${device.tablet} {
    margin: 60px 10px;
  }
`;

export const CloseButton = styled.div`
  position: fixed;
  top: 110px;
  right: 120px;
  border: 1px solid ${p => p.theme.menuBorderColor};
  border-radius: 100%;
  opacity: 1;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: opacity 0.5s linear;
  z-index: 999;

  &:hover {
    opacity: 0.4;
  }

  @media ${device.desktop} {
    top: 60px;
    right: 70px;
  }

  @media ${device.tablet} {
    top: 25px;
    right: 40px;
  }
`;

export const CloseImg = styled.img`
  width: 16px;
  height: 16px;
`;

export const QuestionDetailColorsContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 80px;
  width: 100%;

  @media ${device.tablet} {
    padding-top: 30px;
  }
`;

export const QuestionDetail = styled(titleH3)`
  text-align: left;
`;

export const ColorsDetailContainer = styled.div`
  padding-top: 70px;
  width: 100%;

  @media ${device.tablet} {
    padding-top: 20px;
  }
`;

export const ColorDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  padding-bottom: 70px;
  width: 16.66%;
  max-width: 160px;
  height: 240px;

  @media ${device.laptopL} {
    width: 20%;
  }

  @media ${device.laptopS} {
    width: 25%;
  }

  @media ${device.tablet} {
    width: 33.333%;
    padding-bottom: 30px;
    height: 210px;
  }

  @media ${device.tabletS} {
    width: 50%;
  }

  @media ${device.mobileM} {
    width: 100%;
  }
`;

export const ColorDetailContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.thirdColor};
  padding: 10px;
  padding-bottom: 0px;
`;

export const ColorDetailBg = styled.div`
  background-color: ${p => p.bgColor};
  width: 100%;
  height: 75px;
  position: relative;
`;

export const ColorDetailName = styled.div`
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 15px;
  height: 48px;
  overflow: hidden;

  @media ${device.tablet} {
    font-size: 15px;
  }
`;

export const ImageDetailBg = styled.div`
  max-width: 100%;
  height: 75px;

  & > div {
    & > div {
      margin: auto;
    }
  }
`;

export const ViewMoreContainer = styled.div`
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #A07D48;
  border-radius: 20px;
  padding: 6px 30px;
  transition: background-color 0.5s linear;

  & svg {
    & #arrowright {
      transition: fill 0.5s linear;
      fill: #ffffff; 
    }
  }

  @media ${device.laptop} {
    padding: 5px 25px;
  }

  @media ${device.tablet} {
    padding: 4px 18px;

    & svg {
      width: 15px;
      height: 13px;
    }
  }
`;

export const ViewMore = styled.div`
  font-family: 'Titillium Web', sans-serif;
  color: #ffffff;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  transition: color 0.5s linear;
  margin-right: 13px;

  @media ${device.tablet} {
    font-size: 16px;
    line-height: 22px;
    margin-right: 10px;
  }
`;