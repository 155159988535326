import TextInput from "../../components/inputs/TextInput";
import SelectLanguageInput from "../../components/inputs/SelectLanguageInput";

export const brideFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "address",
    type: "text",
    label: "ADDRESS",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "profession",
    type: "text",
    label: "OCCUPATION",
    component: TextInput,
    disabled: false,
    asterisc: false
  }
];

export const groomFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "address",
    type: "text",
    label: "ADDRESS",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "profession",
    type: "text",
    label: "OCCUPATION",
    component: TextInput,
    disabled: false,
    asterisc: false
  }
];

export const contactFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    asterisc: false
  },
  {
    name: "address",
    type: "text",
    label: "ADDRESS",
    component: TextInput,
    asterisc: false
  }
];

export const guestFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    asterisc: false,
    disabled: true,
  },
];

export const weddingFields = [
  {
    name: "date",
    type: "text",
    label: "DATE",
    disabled: true,
    component: TextInput
  },
  {
    name: "wedding_place",
    type: "text",
    label: "SPACE",
    disabled: true,
    component: TextInput
  },
  {
    name: "organizer",
    type: "type",
    label: "ORGANIZER",
    disabled: true,
    component: TextInput
  },
  {
    name: "coordinator",
    type: "text",
    label: "COORDINATOR",
    disabled: true,
    component: TextInput
  },
  {
    name: "language",
    type: "select",
    label: "COMMUNICATION_LANGUAGE",
    disabled: false,
    component: SelectLanguageInput
  },
  // {
  //   name: "language",
  //   type: "select",
  //   label: "LANGUAGE_ON_WEDDING_DAY",
  //   disabled: false,
  //   component: SelectLanguageInput,
  //   language: [
  //     {code: 'pt', name: 'Português'},
  //     {code: 'en', name: 'English'},
  //     {code: 'pt_en', name: 'Português e English'},
  //   ]
  // }
];
