import TextInput from "../../components/inputs/TextInput";
import SelectLanguageInput from "../../components/inputs/SelectLanguageInput";
import MaterialNumberInput from "../../components/inputs/MaterialNumberInput";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import SelectInput from "../../components/inputs/SelectInput";
import { visitedLocals } from "../agenda/ReserveInfoModal";

export const brideFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "address",
    type: "text",
    label: "ADDRESS",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "profession",
    type: "text",
    label: "OCCUPATION",
    component: TextInput,
    disabled: false,
    asterisc: false
  }
];

export const groomFields = [
  {
    name: "name",
    type: "text",
    label: "NAME_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "contact",
    type: "tel",
    label: "CELLPHONE_WD",
    component: TextInput,
    disabled: false,
    asterisc: true
  },
  {
    name: "email",
    type: "email",
    label: "EMAIL_ADDRESS",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
  {
    name: "address",
    type: "text",
    label: "ADDRESS",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "profession",
    type: "text",
    label: "OCCUPATION",
    component: TextInput,
    disabled: false,
    asterisc: false
  }
];

export const guestFields = [
  {
    name: "name",
    type: "text",
    label: "ALTERNATIVE_GUEST_NAME",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "contact",
    type: "tel",
    label: "ALTERNATIVE_GUEST_CELLPHONE",
    component: TextInput,
    disabled: false,
    asterisc: false
  },
  {
    name: "email",
    type: "email",
    label: "ALTERNATIVE_GUEST_EMAIL",
    component: TextInput,
    disabled: true,
    asterisc: false
  },
];

export const weddingFields = [
  {
    name: "language",
    type: "select",
    label: "COMMUNICATION_LANGUAGE",
    disabled: false,
    component: SelectLanguageInput
  }
];
