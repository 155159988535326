import DashboardPage from "../../pages/dashboard/DashboardPage";
import AboutUs from "../../pages/aboutUs/AboutUs";
import InformationPage from "../../pages/information/InformationPage";
import InformationPageDetail from "../../pages/information/InformationPageDetail";
import Decoration from "../../pages/decoration/Decoration";
import DecorationDetail from "../../pages/decoration/DecorationDetail";
import Food from "../../pages/decoration/Food";
import FoodDetail from "../../pages/decoration/FoodDetail";
import Drink from "../../pages/decoration/Drink";
import DrinkDetail from "../../pages/decoration/DrinkDetail";
import Upgrade from "../../pages/upgrade/Upgrade";
import UpgradeDetail from "../../pages/upgrade/UpgradeDetail";
import OurStyle from "../../pages/decoration/OurStyle";
import OurSuggestions from "../../pages/ourStyle/OurSuggestions";
import YourSuggestions from "../../pages/ourStyle/YourSuggestions";
import Guests from "../../pages/decoration/Guests";
import GuestsProvisionalList from "../../pages/guests/GuestsProvisionalList";
import GuestsRoomPlan from "../../pages/guests/GuestsRoomPlan";
import GuestsDefinitiveList from "../../pages/guests/GuestsDefinitiveList";
import Party from "../../pages/decoration/Party";
import PartyDetail from "../../pages/decoration/PartyDetail";
import Ceremony from "../../pages/decoration/Ceremony";
import CeremonyDetail from "../../pages/decoration/CeremonyDetail";
import Choices from "../../pages/choices/Choices";
import Staff from "../../pages/decoration/Staff";
import StaffInternal from "../../pages/staff/StaffInternal";
import StaffExternal from "../../pages/staff/StaffExternal";
import Budget from "../../pages/budget/Budget";
import NotFound from "../../pages/notFound/NotFoundPage";
import OurDay from "../../pages/ourDay/OurDay";
import News from "../../pages/news/News";
import NewsDetail from "../../pages/news/NewsDetail";
import TermsConditions from "../../pages/dynamicPages/TermsConditions";
import PrivacyPolicy from "../../pages/dynamicPages/PrivacyPolicy";
import HelpPage from "../../pages/dynamicPages/HelpPage";
import HomePage from "../../pages/home/HomePage";
import AgendaPage from "../../pages/agenda/AgendaPage";
import ProfileReserve from "../../pages/profileReserve/ProfileReserve";
import ReservePage from "../../pages/reserve/ReservePage";
import ConfirmReservePage from "../../pages/reserve/ConfirmReservePage";
import WaitingListPage from "../../pages/reserve/WaitingListPage";
import InterestListPage from "../../pages/reserve/InterestListPage ";
import VisitAgendaPage from "../../pages/agenda/VisitAgendaPage";
import VisitListPage from "../../pages/reserve/VisitListPage";
import RoomPlanEditor from "../../pages/guests/RoomPlanEditor";
import GuestsMapList from "../../pages/guests/GuestsMapList";

export default [
  {
    path: "/",
    component: DashboardPage,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/information",
    component: InformationPage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: "/information/:id",
    component: InformationPageDetail,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: "/about-us",
    component: AboutUs,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/decoration",
    component: Decoration,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/decoration/:id`,
    component: DecorationDetail,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/food-selection",
    component: Food,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/food-selection/:id`,
    component: FoodDetail,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/drink-selection",
    component: Drink,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/drink-selection/:id`,
    component: DrinkDetail,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/upgrade",
    component: Upgrade,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/upgrade/:id`,
    component: UpgradeDetail,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/our-style",
    component: OurStyle,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/our-style/5e42e52b29196b814f617d18",
    component: OurSuggestions,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/our-style/5e42e53429196b814f617d30",
    component: YourSuggestions,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/guests",
    component: Guests,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/guests/65b37c49b42ba97e4a0db4c9`,
    component: GuestsMapList,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/guests/5e2ada5d47f38787fd9e8423`,
    component: GuestsProvisionalList,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/guests/5e2adaa747f38787fd9e8424`,
    component: GuestsRoomPlan,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/guests/65afd1a7b42ba97e4a0db4b3`,
    component: RoomPlanEditor,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/guests/5e2adad647f38787fd9e8425`,
    component: GuestsDefinitiveList,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/party-selection",
    component: Party,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/party-selection/:id`,
    component: PartyDetail,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/ceremony",
    component: Ceremony,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/ceremony/:id`,
    component: CeremonyDetail,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/choices",
    component: Choices,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/staff",
    component: Staff,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/staff/5e3181e69621e5d6a3d89740`,
    component: StaffInternal,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/staff/5e3182279621e5d6a3d89741`,
    component: StaffExternal,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/budget",
    component: Budget,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/our-day`,
    component: OurDay,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: `/404`,
    component: NotFound,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: `/news`,
    component: News,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: `/news/:page`,
    component: News,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: `/news/detail/:id`,
    component: NewsDetail,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: `/terms-and-conditions`,
    component: TermsConditions,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: `/privacy-policy`,
    component: PrivacyPolicy,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: `/help`,
    component: HelpPage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: "/home",
    component: HomePage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: false
  },
  {
    path: "/agenda",
    component: AgendaPage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: false
  },
  {
    path: "/reserves",
    component: ReservePage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: false
  },
  {
    path: "/confirmations",
    component: ConfirmReservePage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: false
  },
  {
    path: "/waiting-list",
    component: WaitingListPage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: "/waiting-agenda",
    component: AgendaPage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: "/interest-list",
    component: InterestListPage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: false
  },
  {
    path: "/interest-agenda",
    component: AgendaPage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: false
  },
  {
    path: "/visit-list",
    component: VisitListPage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: "/visit-agenda",
    component: VisitAgendaPage,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: true
  },
  {
    path: "/meeting-list",
    component: VisitListPage,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/meeting-agenda",
    component: VisitAgendaPage,
    isAuthenticated: true,
    reserveUserAllowed: false,
    weddingUserAllowed: true
  },
  {
    path: "/profile",
    component: ProfileReserve,
    isAuthenticated: true,
    reserveUserAllowed: true,
    weddingUserAllowed: false
  },
];
