import client from "../config/AxiosConfig";

export const GetGuests = async () =>
  await client.get('/my-wedding/guests');

export const EditProvisoryInfo = async data =>
  client.put("/my-wedding/guests/provisory", data);

export const AddRoomPlan = async data =>
  client.put("/my-wedding/guests/plan", data);


// Room plan editor
export const GetOurGuestsEditor = async () =>
  await client.get('/my-wedding/roomPlanEditor');

export const GetCoupleTable = async () =>
  await client.get('/my-wedding/coupleTableEditor');

export const CheckUpdateCoupleTable = async (data) =>
  await client.put('/my-wedding/checkUpdaCoupleTable', data);

export const SaveRoomPlanEditor = async (data = {}) =>
  await client.put('/my-wedding/roomPlanEditor', data);

export const SaveRoomPlanType = async (data = {}) =>
  await client.put('/my-wedding/saveRoomPlanType', data);

export const SubmitRoomPlan = async (data = {}) =>
  await client.put('/my-wedding/submitRoomPlan', data);

export const GetFoodResctrictions = async () =>
  await client.get('/my-wedding/foodRestrictions');

export const GetTableTypes = async () =>
  await client.get('/my-wedding/tableTypes');

  export const NotifyChangeRoomPlan = async () =>
  await client.put('/my-wedding/notifyChangeRoomPlan');