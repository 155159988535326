import React, { useState } from "react";
import { connect } from "react-redux";
import { withLocalize } from "react-localize-redux";
import {
  CardContainer,
  CardContent,
  BgContainer,
  DetailContainer,
  Title,
  Text,
  PriceQuantityRow,
  Price,
  SelectContainer,
  CheckboxContainer,
  ImageIcon,
  OptionTextContainer,
  ViewFullDesc,
  HideFullDescContainer,
  Subtitle,
} from "./OptionCardStyles";
import CheckboxInput from "../inputs/CheckboxInput";
import ImageComponent from "../images/ImageComponent";
import Alert from "../alert/Alert";
import { checkFoodOption } from "../../infra/services/utils/Options";
import EnlargeIcon from "../../components/svg/Enlarge";
import ImageModal from "../../components/imageModal/ImageModal";
import truncate from "truncate-html";
import ArrowLeft from "../svg/ArrowLeft";
import RoundButton from "../../components/buttons/RoundButton";
import { checkExtraCost, checkQuestionWithSidedishes, checkSidedishesOriginal, getFoodExtraCost, getPlateSidedishesName } from "../../infra/services/utils/SidedishesUtils";
import { isString } from "./OptionUtils";
import styledComponents from "styled-components";
import { Icon, Tooltip } from "antd";

const PriceWithWarning = styledComponents.div`
  display: flex;
  align-content: center;
  align-items: center;

  .anticon { 
    color: ${({ premium }) => premium ? '#bc9222' : '#fb635b'};
    margin-right: 5px;
    font-size: 20px;
  }
`;

const textLimit = 40;

const FoodCard = ({
  question,
  record,
  canEditSection,
  isProcessClosed,
  input,
  options,
  sidedishes,
  translate,
  activeLanguage,
  index,
  wedding,
}) => {
  const [showImageModal, setImageModal] = useState(false);
  const [showFullDesc, setFullDesc] = useState(false);

  const foodWithSidedishes = checkQuestionWithSidedishes(question);

  let text = record.description ? record.description[activeLanguage?.code] : "";
  if (text?.length > textLimit && !showFullDesc) {
    text = truncate(text, textLimit);
  }

  // const selected = input?.value && input.value.find((id) => id === record._id);
  const selected = input?.value && input.value.find(resp => resp && resp.id ? resp.id === record._id : resp === record._id);
  let disabled = false;
  const isPremiumFood = wedding?.upgrade && wedding?.upgrade?.hasPremiumOptions && record && record?.isPremiumFood ? true : false;

  if (input.value.length > 0 && !input.value.find(resp => resp.id ? resp.id === record._id : resp === record._id)) {
    //We have to check every element in input.value
    //If at least one option still exists in this question, then we can disable this option
    //If not then we cannot disable any option as the current option doesn't exist anymore
    for (let i = 0; i < input.value.length; i++) {
      if (checkFoodOption(input.value[i], options)) {
        disabled = true;
        break;
      }
    }
  }

  const toggleOption = (record) => {
    if (!disabled) {
      if (isProcessClosed) {
        Alert.new({
          type: "success",
          title: translate("BLOCKED_ANSWER_TITLE"),
          text: translate("PROCESS_CLOSED_TEXT"),
        });
      } else if (canEditSection) {
        const hasSidedishe = record && record.category && (record.category === 'meat' || record.category === 'fish');
        const list = [...input.value];
        const index = list.findIndex((elem) => elem && elem.id ? elem.id === record._id : elem === record._id);

        if (index >= 0) list.splice(index, 1);
        else {
          if (hasSidedishe) {
            const response = {
              id: record._id,
              sidedishes: record && record.sidedishes && Array.isArray(record.sidedishes) && record.sidedishes.length > 0 ? record.sidedishes.map(m => m._id) : []
            };
            list.push(response);
          } else if (isPremiumFood && record && record.costPriceExtra !== undefined) {
            list.push({ id: record._id, cost: record?.costPriceExtra });
          } else list.push(record._id);
        }

        input.onChange(list);
      } else {
        Alert.new({
          type: "success",
          title: translate("BLOCKED_ANSWER_TITLE"),
          text: translate("BLOCKED_ANSWER_TEXT"),
        });
      }
    }
  };

  const openImageModal = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setImageModal(true);
  };

  const closeImageModal = () => {
    setImageModal(false);
  };

  const openFullDesc = (e) => {
    e.preventDefault();
    e.stopPropagation();

    text = record.description ? record.description[activeLanguage?.code] : "";
    setFullDesc(true);
  };

  const closeFullDesc = (e) => {
    e.preventDefault();
    e.stopPropagation();

    text = record.description ? record.description[activeLanguage?.code] : "";

    if (text?.length > textLimit) {
      text = truncate(text, textLimit);
    }

    setFullDesc(false);
  };

  const getSidedishes = () => {
    if (foodWithSidedishes) {
      const foodSidedishes = record && record.sidedishes && Array.isArray(record.sidedishes) && record.sidedishes.length > 0
        ? record.sidedishes
        : [];

      const answerSidedishes = selected
        ? selected.sidedishes && Array.isArray(selected.sidedishes) && selected.sidedishes.length > 0
          ? selected.sidedishes
          : []
        : undefined;

      const sidedishesList = checkSidedishesOriginal(foodSidedishes, answerSidedishes)
        ? answerSidedishes
        : foodSidedishes;

      return getPlateSidedishesName(sidedishesList, sidedishes, activeLanguage);
    } else return '';
  }

  const hasExtraCost = () => {
    if (foodWithSidedishes) {
      const sidedishesList = selected && selected.sidedishes && Array.isArray(selected.sidedishes) && selected.sidedishes.length > 0 ?
        selected.sidedishes.map(s => sidedishes.find(side => side._id === s))
        : record.sidedishes ? record.sidedishes : [];
      return checkExtraCost(record, sidedishesList);
    } else return getExtraCost() !== null ? true : false;
  }

  const getExtraCost = () => {
    if (foodWithSidedishes) {
      const sidedishesList = selected && selected.sidedishes && Array.isArray(selected.sidedishes) && selected.sidedishes.length > 0 ?
        selected.sidedishes.map(s => sidedishes.find(side => side._id === s))
        : record.sidedishes ? record.sidedishes : [];
      return getFoodExtraCost(record, sidedishesList);
    } else return getCost();
  }

  const getCost = () => {
    let cost = null;

    if (input?.value) {
      let found = input.value.find(x => x && x.id && record ? x.id === record._id : x === record._id);

      if (found && found.cost && found.cost !== undefined) {
        cost = isString(found.cost) ? parseFloat(found.cost) : found.cost;
      } else {
        // cost = isPremiumFood
        //   ? record?.costPriceExtra
        //   : record.extra_cost
        //     ? record.extra_cost
        //     : null;
        cost = record?.extra_cost
          ? record.extra_cost
          : null;
      }
    } else {
      // cost = isPremiumFood
      //   ? record?.costPriceExtra
      //   : record.extra_cost
      //     ? record.extra_cost
      //     : null;
      cost = record?.extra_cost
        ? record.extra_cost
        : null;
    }

    return cost;
  }

  return (
    <React.Fragment>
      <CardContainer
        type={question.type}
        disabled={disabled}
        onClick={() => toggleOption(record)}
      >
        <CardContent selected={selected}>
          <SelectContainer>
            <CheckboxContainer>
              <CheckboxInput
                checkboxType="round"
                meta={{}}
                input={{ value: selected, onChange: () => null }}
              />
            </CheckboxContainer>
          </SelectContainer>
          <BgContainer border={false}>
            {record.image?.url ? (
              <div onClick={(e) => openImageModal(e)}>
                <ImageIcon>
                  <EnlargeIcon />
                </ImageIcon>
                <ImageComponent
                  ratio={0.6}
                  url={record.image?.url}
                  color={record.image?.color}
                />
              </div>
            ) : (
              <ImageComponent
                ratio={0.6}
                url={record.image?.url}
                color={record.image?.color}
              />
            )}
          </BgContainer>
          <DetailContainer>
            {hasExtraCost() && (
              <PriceQuantityRow>
                {isPremiumFood
                  ? <PriceWithWarning premium={isPremiumFood}>
                    <Tooltip title={translate('WARNING_OPTION_WEDDING_PRIME')}>
                      <Icon type={'sketch'} />
                    </Tooltip>
                    <Price>{`${getExtraCost()}€`}</Price>
                  </PriceWithWarning>
                  : <Price>{`${getExtraCost()}€`}</Price>
                }
              </PriceQuantityRow>
            )}
            {/* {record.extra_cost && (
              <PriceQuantityRow>
                <Price>{`${record.extra_cost}€`}</Price>
              </PriceQuantityRow>
            )} */}
            <Title>{record.name[activeLanguage?.code]?.trim()}{getSidedishes()}</Title>
            {/* <Subtitle>{getSidedishes()}</Subtitle> */}
          </DetailContainer>
          {record.description && (
            <OptionTextContainer showFullDesc={showFullDesc} index={index}>
              <Text
                className="optionText"
                selected={selected}
                showFullDesc={showFullDesc}
              >
                {text}
                {record.description[activeLanguage?.code].length > textLimit &&
                  !showFullDesc && (
                    <ViewFullDesc onClick={(e) => openFullDesc(e)}>
                      &nbsp;<span>{translate("VIEW_MORE")}</span>{" "}
                      <span>
                        <ArrowLeft />
                      </span>
                    </ViewFullDesc>
                  )}
                {showFullDesc && (
                  <HideFullDescContainer>
                    <RoundButton
                      text={translate("CLOSE")}
                      onClick={closeFullDesc}
                    />
                  </HideFullDescContainer>
                )}
              </Text>
            </OptionTextContainer>
          )}
        </CardContent>
      </CardContainer>
      {showImageModal && (
        <ImageModal
          title={record.name[activeLanguage?.code]}
          image={record.image?.url}
          color={record.image?.color}
          handleCancel={closeImageModal}
        />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  wedding: state.wedding.wedding,
});

export default withLocalize(connect(mapStateToProps)(FoodCard));
