import React from "react";
import {
  BudgetSectionContainer,
  Title,
  Section,
  Description,
  Amount,
  Date
} from "./BudgetSectionStyles";

const BudgetSection = ({ title, section, text, amount, date }) => {
  return (
    <BudgetSectionContainer>
      {
        title && <Title>{title}</Title>
      }
      {
        section && <Section>{section}</Section>
      }
      {
        text && <Description dangerouslySetInnerHTML={{__html: text}}/> 
      }
      {
        amount && <Amount>{amount}</Amount>
      }
      {
        date && <Date>{date}</Date>
      }
    </BudgetSectionContainer>
  );
}

export default BudgetSection;