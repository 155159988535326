import Upgrade from "../../assets/icons/upgrade.svg";
import Informations from "../../assets/icons/informations.svg";
import Choices from "../../assets/icons/choices.svg";
import Budget from "../../assets/icons/budget.svg";
import Settings from "../../assets/icons/settings.svg";
import Reserves from "../../assets/icons/reserve.svg";
import Calendar from "../../assets/icons/calendar.svg";
import OurReserves from "../../assets/icons/our_reserve.svg";
import Confirmation from "../../assets/icons/confirmation.svg";
import WaitingList from '../../assets/icons/waiting_list.svg';
import InterestList from '../../assets/icons/interest_list.svg';
import VisitList from '../../assets/icons/visit_list.svg';
import { inAgendaTest } from "../../infra/services/utils/Helpers";

const menuElems = [
  {
    name: "UPGRADE",
    img: Upgrade,
    url: "/upgrade",
  },
  {
    name: "INFORMATIONS",
    img: Informations,
    url: "/information",
  },
  {
    name: "CHOICES",
    img: Choices,
    url: "/choices",
    tooltip: 'CONSULT'
  },
  {
    name: "BUDGET",
    img: Budget,
    url: "/budget",
  },
  {
    name: "OUR_WAITING_DATE_LIST",
    img: WaitingList,
    url: "/waiting-list",
    tooltip: 'CONSULT'
  },
  // {
  //   name: "OUR_MEETING_LIST",
  //   img: VisitList,
  //   url: "/meeting-list",
  //   tooltip: 'CONSULT'
  // },
  {
    name: "SETTINGS",
    img: Settings,
    url: "SETTINGS_MENU",
  },
];

if (inAgendaTest()) {
  menuElems.splice(5, 0, {
    name: "OUR_MEETING_LIST",
    img: VisitList,
    url: "/meeting-list",
    tooltip: 'CONSULT'
  });
}

export const menuItems = menuElems;

const menuVisitElems = [
  {
    name: "UPGRADE",
    img: Upgrade,
    url: "/upgrade",
  },
  {
    name: "INFORMATIONS",
    img: Informations,
    url: "/information",
  },
  {
    name: "CHOICES",
    img: Choices,
    url: "/choices",
    tooltip: 'CONSULT'
  },
  {
    name: "BUDGET",
    img: Budget,
    url: "/budget",
  },
  {
    name: "OUR_WAITING_DATE_LIST",
    img: WaitingList,
    url: "/waiting-list",
    tooltip: 'CONSULT'
  },
  // {
  //   name: "OUR_MEETING_LIST",
  //   img: VisitList,
  //   url: "/meeting-list",
  //   tooltip: 'CONSULT'
  // },
  {
    name: "SETTINGS",
    img: Settings,
    url: "SETTINGS_MENU",
  },
];

if (inAgendaTest()) {
  menuVisitElems.splice(5, 0, {
    name: "OUR_VISIT_LIST",
    img: VisitList,
    url: "/visit-list",
    tooltip: 'CONSULT'
  });
}

export const menuVisitItems = menuVisitElems;

const reserveMenuElems = [
  // {
  //   name: "UPGRADE",
  //   img: Upgrade,
  //   url: "/upgrade",
  // },
  {
    name: "INFORMATIONS",
    img: Informations,
    url: "/information",
    tooltip: 'CONSULT'
  },
  {
    name: "AGENDA",
    img: Calendar,
    url: "/agenda",
    tooltip: 'AGENDA_TOOLTIP'
  },
  {
    name: "OUR_RESERVES",
    img: OurReserves,
    url: "/reserves",
    tooltip: 'CONSULT'
  },
  {
    name: "CONFIRMATIONS",
    img: Confirmation,
    url: "/confirmations",
    tooltip: 'CONFIRMATIONS_TOOLTIP'
  },
  {
    name: "OUR_WAITING_DATE_LIST",
    img: WaitingList,
    url: "/waiting-list",
    tooltip: 'CONSULT'
  },
  // {
  //   name: "OUR_INTEREST_DATE_LIST",
  //   img: InterestList,
  //   url: "/interest-list",
  //   tooltip: 'CONSULT'
  // },
  // {
  //   name: "OUR_VISIT_LIST",
  //   img: VisitList,
  //   url: "/visit-list",
  //   tooltip: 'CONSULT'
  // },
  // When to uncomment next lines, go to base routes and allow reserveUser to acess the budget path
  // {
  //   name: "BUDGET",
  //   img: Budget,
  //   url: "/budget",
  // },
  {
    name: "SETTINGS",
    img: Settings,
    url: "SETTINGS_MENU",
  },
];

if (inAgendaTest()) {
  reserveMenuElems.splice(5, 0, {
    name: "OUR_INTEREST_DATE_LIST",
    img: InterestList,
    url: "/interest-list",
    tooltip: 'CONSULT'
  });
  reserveMenuElems.splice(6, 0, {
    name: "OUR_VISIT_LIST",
    img: VisitList,
    url: "/visit-list",
    tooltip: 'CONSULT'
  });
}

export const reserveMenuItems = reserveMenuElems;

export const settingsMenu = [
  {
    name: "HELP",
    url: "/help",
  },
  {
    name: "TERMS_CONDITIONS_WD",
    url: "/terms-and-conditions",
  },
  {
    name: "PRIVACY_POLICY_WD",
    url: "/privacy-policy",
  },
];
