import styled from 'styled-components';
import { device, size } from '../../styles/Responsive';
import { titleH2, titleH3, titleH4, titleH5 } from '../../styles/TextSizes';

export const BudgetContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 990px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 20px;
  margin: auto;
  overflow-x: hidden;

  @media ${device.desktopL} {
    padding: 60px 20px;
  }

  @media ${device.laptop} {
    padding: 50px 20px;
  }

  @media ${device.tablet} {
    padding: 40px 10px;
  }

  @media ${device.mobileL} {
    padding: 30px 10px;
  }
`;

export const BudgetInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 20px;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  color: #000000;
  
  ul {
    list-style-type: none;
    margin-block: 0;
    padding-inline-start: 0;
    width: 100%;
  }

  ul li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 30px;
  }

  ul li.line {
    border-bottom: 2px solid ${p => p.theme.primaryCTA};
  }

  ul li.iban, 
  ul li.updated, 
  ul li.payment-phase .title,
  ul li.payment-phase .price {
    display: flex;
    flex-direction: row !important;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  ul li.updated,
  ul li.iban, 
  ul li.payment-phase {
    margin-bottom: 20px;
  }

  ul li.payment-phase .price .preview {
    margin-right: 30px;
    white-space: nowrap;
  }

  ul li.payment-phase .title b {
    color: #bc9222;
  }
  
  ul li span,
  ul li.payment-phase .subtitle {
    text-align: initial;
    font-size: 16px;
  }

  ul li.payment-phase .subtitle .anticon {
    margin-right: 5px;
  }

  ul li.iban span, 
  ul li.updated span, 
  ul li.payment-phase .title span {
    padding-left: 5px;
  }

  ul li.payment-phase .price span span {
    padding: 0 5px;
  }
`;

export const Title = styled(titleH2)`
  text-align: left;
  width: 100%;
`;

export const Description = styled.div`
  width: 100%;
  text-align: left;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 31px;
  margin-top: 60px;
  white-space: pre-wrap;

  @media ${device.desktopL} {
    margin-top: 40px;
  }

  @media ${device.desktop} {
    margin-top: 30px;
  }

  @media ${device.laptop} {
    font-size: 18px;
    line-height: 26px;
  }

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 24px;
    margin-top: 30px;
  }
`;

export const BudgetTotal = styled(titleH5)`
  width: 100%;
  background-color: ${p => p.theme.thirdColor};
  margin-top: 30px;
  padding: 15px 35px;
  text-align: left;
  line-height: 29px;

  @media ${device.mobileL} {
    padding: 15px 10px;
    font-size: 14px;
  }
`;

export const BudgetGraphsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 60px;

  & > div {
    width: 50%;

    & .recharts-wrapper {
      margin: auto;
    }
  }

  @media ${device.tablet} {
    & > div {
      width: 100%;
    }
  }
`;

export const Subtitle = styled(titleH3)`
  text-align: left;
  width: 100%;
  ${(payment) => payment ? `margin-bottom: 15px;` : ''}
`;

export const SectionTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 90px;

  @media ${device.desktopL} {
    margin-top: 60px;
  }

  @media ${device.desktop} {
    margin-top: 40px;
  }
`;

export const SectionIcon = styled.img`
  margin-right: 20px;
  width: 35px;
  height: auto;

  @media ${device.tablet} {
    width: 25px;
  }
`;

export const SectionTitle = styled(titleH4)`
  line-height: 29px;
`;

export const SectionSubtitle = styled.div`
  width: 100%;
  text-align: left;
  color: #000000;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  padding-top: 10px;
  white-space: pre-wrap;

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 22px;
  }
`;

export const NoRecordsContainer = styled.div`
  width: 100%;
  background-color: ${p => p.theme.thirdColor};
  margin-top: 25px;
  padding: 15px;
`;

export const NoRecords = styled.div`
  width: 100%;
  text-align: left;
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  @media ${device.tablet} {
    font-size: 15px;
    line-height: 22px;
  }
`;