// export const PrimaryColour = "#DECBB3";
export const PrimaryColour = "#bc9222";
export const SecondaryColour = "#ECE8E2";
export const ThirdColour = "#FAF9F9";
export const PrimaryCTA = "#CACACA";
export const SecondaryCTA = "#A5A5A5";
export const AlertColour = "#DE8F8F";
export const InputBorderColour = "#979797";
export const PrimaryBorderColor = "#F3F3F3";
export const SecondaryBorderColor = "#bc9222";
export const MenuBorderColor = "#E7E7E7";
export const SuccessColour = "#52c41a";
export const WarningColour = "#faad14";