import PropTypes from "prop-types";
import React from "react";
import { InputDiv, CheckboxField } from "./InputStyles";
import { MaterialCheckboxLabel } from "./MaterialStyles";

const CheckboxInput = ({
  input,
  meta,
  label,
  first,
  disabled,
  checked,
  style,
  checkboxType,
  onClick,
  onClickLabel
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const labelClick = e => {
    if (onClickLabel) {
      e.preventDefault();
      e.stopPropagation();
      onClickLabel();
    }
  };
  return (
    <InputDiv first={first}>
      <CheckboxField
        disabled={disabled}
        style={style}
        checked={input.value || checked ? true : false}
        onChange={input.onChange}
        error={showError ? 1 : 0}
        checkboxType={checkboxType}
        onClick={onClick}
      >
        {label ? (
          <MaterialCheckboxLabel
            filled
            clickable={onClickLabel}
            showError={showError}
            onClick={labelClick}
          >
            {label}
          </MaterialCheckboxLabel>
        ) : null}
      </CheckboxField>
    </InputDiv>
  );
};

CheckboxInput.propTypes = {
  label: PropTypes.string,
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  checkboxType: PropTypes.string,
  onClick: PropTypes.func
};

CheckboxInput.defaultProps = {
  checkboxType: "square"
};

export default CheckboxInput;
