import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Checkbox, Form, Popconfirm, Icon } from 'antd';
import { connect, useSelector } from 'react-redux';
import { Field, reduxForm, initialize, formValueSelector, FieldArray } from 'redux-form';
import { withLocalize } from 'react-localize-redux';
import FormSection from 'redux-form/lib/FormSection';
import styled from 'styled-components';
import { Spin, Form as AntForm } from 'antd';
import { isValidPhoneNumber } from 'react-phone-number-input';
import FormValidator from '../../infra/services/validations/FormValidator';
import NumberInput from '../../components/inputs/NumberInput';
import CheckboxInput from '../../components/inputs/CheckboxInput';
import SelectInput from '../../components/inputs/SelectInput';
import { MaterialInputNumber } from '../../components/inputs/MaterialStyles';
import MaterialNumberInput from '../../components/inputs/MaterialNumberInput';
import moment from 'moment';
import Table from '../../components/table/Table';
import OrdinalNumberComponent from '../../components/ordinalNumber/OrdinalNumberComponent';
import { locals } from './AgendaPage';
import CurrencyComponent from '../../components/currency/CurrencyComponent';
import Alerts from '../../components/alert/Alert';
import DateInput from '../../components/inputs/DateInput';
import TextInput from '../../components/inputs/TextInput';
import { TableButton } from '../../styles/BasicStyles';

export const Title = styled.div`
  font-family: 'Titillium Web', sans-serif;
  color: #bc9222;
  font-weight: inherit;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 15px 0 0 0;
  border-bottom: 1px solid #bc9222
`

export const ErroSpan = styled.span`
  font-family: 'Titillium Web', sans-serif;
  color: red;
  font-size: 11px;
  line-height: 39px;
  margin: 0;
  padding: 0;
`

export const FormContainer = styled(Form)`
  width: 100%;
`;

export const BaseForm = styled(AntForm)`
  display: inline-block;
  width: 100%;
`;

export const TableContent = styled.div`
    width: 100%;
    margin-top: ${({ first }) => (first ? '0px' : '15px')}

    table tr th {
        color: black !important;
    }

    .ant-table {
        overflow: auto;
    }

    .ant-table table {
        border-radius: 0 !important;
    }

    .ant-table-thead > tr:first-child > th:first-child {
        border-top-left-radius: 0px !important;
    }

    .ant-table-thead > tr:first-child > th:last-child {
        border-top-right-radius: 0px !important;
    }

    .ant-empty-image {
        height: 0 !important;
        margin-bottom: 0 !important;
    }

    .ant-table-placeholder {
        padding: 10px 16px !important;
    }
`;

export const DateColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

  .date {
    font-size: inherit;
    font-weigth: bold;
  }

  .weekdayName {
    font-size: 11px;
    color: light-gray;
  }
`;

const solar = 'Solar da Levada';
const lago = 'Quinta Lago dos Cisnes';
export const visitedLocals = [
    {
        _id: 1,
        name: {
            pt: solar,
            en: solar,
            es: solar,
            fr: solar
        }
    },
    {
        _id: 2,
        name: {
            pt: lago,
            en: lago,
            es: lago,
            fr: lago
        }
    },
    {
        _id: 3,
        name: {
            pt: 'Ambos os espaços',
            en: 'Both venues',
            es: 'Ambos espacios',
            fr: 'Les deux espaces'
        }
    },
];

const validations = (values) => {
    let errors = {};

    const validations = {
        expectedMinPeople: "required|isInteger|minNumber:1",
    }

    if (values.visitedPlace) validations['visitedWeddingPlace'] = "required";
    // if (values.placeToVisit) {
    //     validations['weddingPlaceToVisit'] = "required";
    //     validations['shecheduleToVisit'] = "required";
    // }

    errors = FormValidator.make(validations)(values);

    return errors;
};

class ReserveInfoModal extends React.Component {
    state = {
        columns: [
            {
                title: this.props.translate('WAITING_LIST'),
                titleKey: 'WAITING_LIST',
                width: '80px',
                render: data => {
                    const { activeLanguage } = this.state;
                    return <OrdinalNumberComponent value={data.order} activeLanguage={activeLanguage} />
                }
            },
            // {
            //     title: this.props.translate('WEEKDAY'),
            //     titleKey: 'WEEKDAY',
            //     render: data => {
            //         const { activeLanguage } = this.props;
            //         return moment(data.date).locale(activeLanguage.code).format('dddd')
            //     }
            // },
            {
                title: this.props.translate('DATE'),
                titleKey: 'DATE',
                width: '85px',
                render: data => {
                    const { activeLanguage } = this.props;
                    return data?.date
                        ? <DateColumn>
                            <span className='date'>{moment.utc(data.date).format('DD-MM-YYYY')}</span>
                            <span className='weekdayName'>{moment.utc(data.date).locale(activeLanguage.code).format('dddd')}</span>
                        </DateColumn>
                        : '';
                }
            },
            {
                title: this.props.translate('PRICE_BY_PAX'),
                titleKey: 'PRICE_BY_PAX',
                render: data => {
                    const sameDate = data?.event?.resource?.weddingReserve && moment.utc(data?.event?.resource?.weddingReserve?.date).isSame(moment.utc(data.date), 'day');
                    const price = sameDate && data?.event?.resource?.weddingReserve?.priceObj?.minPrice
                        ? data?.event?.resource?.weddingReserve?.priceObj?.minPrice
                        : data?.event?.resource?.price?.minPrice
                            ? data?.event?.resource?.price?.minPrice
                            : 0;
                    return <CurrencyComponent value={price} />
                }
            },
            {
                title: this.props.translate('PAX'),
                titleKey: 'PAX',
                width: '60px',
                render: data => {
                    const sameDate = data?.event?.resource?.weddingReserve && moment.utc(data?.event?.resource?.weddingReserve?.date).isSame(moment.utc(data.date), 'day');
                    const pax = sameDate && data?.event?.resource?.weddingReserve?.priceObj?.minPax
                        ? data?.event?.resource?.weddingReserve?.priceObj?.minPax
                        : data?.event?.resource?.price?.minPax
                            ? data?.event?.resource?.price?.minPax
                            : 0;
                    return pax;
                }
            },
            {
                title: this.props.translate('ACTIONS'),
                titleKey: 'ACTIONS',
                render: data => {
                    const { translate } = this.props;

                    return <React.Fragment>
                        {
                            (data?.event?.resource?.weddingReserve?.sentWaitingNotification === undefined ||
                                (data?.event?.resource?.weddingReserve?.sentWaitingNotification !== undefined &&
                                    data?.event?.resource?.weddingReserve?.sentWaitingNotification === false)) &&
                            <TableButton onClick={() => this.props.editDate(data)}>
                                <Icon type="edit" />
                                {translate('EDIT_RESERVE')}
                            </TableButton>
                        }
                        <TableButton onClick={e => e.stopPropagation()}>
                            <Popconfirm
                                placement="topRight"
                                title={this.getRemoveMessage()}
                                okText={translate('DELETE')}
                                cancelText={translate('CANCEL')}
                                onConfirm={() => this.props.deleteDate(data)}>
                                <Icon type="delete" />
                                {translate('DELETE_RESERVE')}
                            </Popconfirm>
                        </TableButton>
                    </React.Fragment>
                }
            }
        ],
        // reserveList: [],
        // rowsSolar: [],
        // rowsLago: [],
        wedding: null,
        activeLanguage: { code: 'pt' }
    }

    componentDidMount() {
        const { initialize, dispatch, initialValues, reserves } = this.props;

        this.setState({
            // reserveList: reserves ? reserves : [],
            wedding: initialValues ? initialValues : null,
        });
    }

    componentDidUpdate(prevProps, props) {
        const { translate } = this.props;
        let { columns } = this.state;

        if (prevProps.activeLanguage !== this.props.activeLanguage) {
            this.setState({ activeLanguage: this.props.activeLanguage });

            // If activeLanguage changes, then update column titles in table
            if (prevProps.activeLanguage.code !== this.props.activeLanguage.code) {
                for (let index = 0; index < columns.length; index++) {
                    const column = columns[index];
                    if (column.titleKey) column.title = translate(column.titleKey);
                }
                this.setState({ columns });
            }
        }

    }

    getRemoveMessage = () => {
        const { translate } = this.props;

        return <div>
            <div>{translate('DELETE_RESERVE_QUESTION')}</div>
            <div>{translate('DELETE_RESERVE_INFO')}</div>
        </div>
    }

    onSubmit = (values) => {
        const { reset, dispatch, submit, onSubmit, translate } = this.props;

        dispatch(submit('reserve_info_form'));
    }

    // Clear the fields when submit is successful
    clearFields = () => {
        const { dispatch, onClose } = this.props;
        dispatch(initialize('reserve_info_form', {}, false));
        onClose();
    }

    getRows = (local) => {
        const { reserves } = this.props;

        return reserves.length > 0
            ? reserves.filter(f => f.weddingPlace === local)
                .map(m => ({ ...m, date: m?.date && moment.utc(m.date).isValid() ? moment.utc(m.date).toISOString() : m.date }))
                .sort((a, b) => a.date && b.date && a.date > b.date ? 1 : -1)
            : [];
    }

    render() {
        const { translate, isOpen, visitedPlace, placeToVisit, expectedMinPeople } = this.props;
        const { handleSubmit, onClose, onSubmit, loading } = this.props;
        const { columns, rowsSolar, rowsLago } = this.state;

        return (
            <Modal
                visible={isOpen}
                title={translate('END_RESERVE')}
                maskClosable
                onCancel={() => {
                    this.clearFields();
                    // onClose();
                }}
                footer={[
                    <Button
                        key='cancel'
                        type='default'
                        onClick={this.clearFields}>
                        {translate('CANCEL')}
                    </Button>,
                    <Button
                        key='submit'
                        type='primary'
                        onClick={handleSubmit(this.onSubmit)}>
                        {translate('SAVE')}
                    </Button>
                ]}
            >

                {/* List reserves dates separated by place */}
                {locals.map((local, index) => (
                    <TableContent key={index} first={index > 0 ? false : true}>
                        <Title>{translate('RESERVES')} {local.name}</Title>
                        <Table
                            columns={columns}
                            rows={this.getRows(local._id)}
                            showHeader={true}
                            emptyText={translate('NO_RESERVES_BY_SPACE')}
                            rowKey={'event.id'}
                            hasPagination={false}
                            loading={loading}
                        />
                    </TableContent>
                ))}

                <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
                    <Field
                        component={MaterialNumberInput}
                        label={translate('EXPECTED_MIN_PEOPLE') + ' *'}
                        name={`expectedMinPeople`}
                        step={1}
                        min={1}
                        translate={translate}
                        labelFixed={true}
                    />
                    {expectedMinPeople && expectedMinPeople !== '' && expectedMinPeople < 140 &&
                        <p>{translate('WARNING_MIN_PEOPLE')}</p>
                    }
                    <Field
                        component={CheckboxInput}
                        name={'visitedPlace'}
                        label={translate('VISITED_PLACE')}
                    />
                    {visitedPlace
                        ? <Field
                            component={SelectInput}
                            data={visitedLocals}
                            dataKey='_id'
                            dataLabel='name'
                            translatable={true}
                            name={'visitedWeddingPlace'}
                            allowClear={false}
                            label={translate('VISITED_PLACE_NAME') + ' *'}
                        />
                        : <React.Fragment>
                            {/* <Field
                                component={CheckboxInput}
                                name={'placeToVisit'}
                                label={translate('QUESTION_SCHEDULE_VISIT')}
                            />
                            {placeToVisit ?
                                <React.Fragment>
                                    <Field
                                        component={SelectInput}
                                        data={visitedLocals}
                                        dataKey='_id'
                                        dataLabel='name'
                                        translatable={true}
                                        name={'weddingPlaceToVisit'}
                                        allowClear={false}
                                        label={translate('PLACE_NAME_TO_VISIT') + ' *'}
                                    />
                                    <Field
                                        component={TextInput}
                                        name={'shecheduleToVisit'}
                                        label={translate('SCHEDULE_TO_VISIT') + ' *'}
                                    />
                                </React.Fragment>
                                : <Field
                                    component={CheckboxInput}
                                    name={'wantToVisitWithoutInfo'}
                                    label={translate('WANT_VISIT_MISSING_INFO')}
                                />
                            } */}
                        </React.Fragment>
                    }
                </BaseForm>

            </Modal>
        );
    }
};

const selector = formValueSelector('reserve_info_form');

ReserveInfoModal = reduxForm({
    form: 'reserve_info_form',
    // enableReinitialize: true,
    validate: validations,
    // onSubmitSuccess: (result, dispatch) => dispatch(initialize('register_form', {}, false))
})(ReserveInfoModal);

const mapStateToProps = state => ({
    expectedMinPeople: selector(state, 'expectedMinPeople'),
    visitedPlace: selector(state, 'visitedPlace'),
    // placeToVisit: selector(state, 'placeToVisit'),
});

export default withLocalize(connect(mapStateToProps)(ReserveInfoModal))