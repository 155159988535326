import styled from "styled-components";
import { device } from '../../styles/Responsive';
import { titleH1, body2 } from '../../styles/TextSizes';
import { Collapse } from "antd";
const { Panel } = Collapse;

const mobileMenuTransition = "0.5s";
const settingsMenuTransition = "0.5s";

/******************************** Styles for HEADER ********************************/

export const Header = styled.div`
  height: 108px;
  width: 100%;
  background-color: ${p => p.theme.thirdColor};
  position: fixed;
  z-index: 1000;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  @media ${device.laptop} {
    border-bottom: 1px solid ${p => p.theme.menuBorderColor};
  }

  @media ${device.desktopL} {
    height: 89px;
  }

  @media ${device.desktop} {
    height: 80px;
  }

  @media ${device.laptopL} {
    height: 66px;
  }

  @media ${device.mobileL} {
    height: 60px;
  }  
`;

export const LogoContainer = styled.div`
  flex: 1;
  max-width: 105px;
  min-width: 105px;
  height: 100%;
  text-align: center;
  background-color: white;
  padding: 5px;
  border-bottom: 1px solid ${p => p.theme.menuBorderColor};
  border-right: 1px solid ${p => p.theme.menuBorderColor};

  @media ${device.laptop} {
    border-bottom: none;
  }

  @media ${device.tablet} {
    max-width: 50px;
    min-width: 50px;
  }
`;

export const LogoImg = styled.img`
  height: 100%;
  width: 100%;
  max-width: 77px;
  min-width: 40px;
  cursor: pointer;
`;

export const HeaderMainContainer = styled.div`
  flex: ${({reservePhase}) => reservePhase ? 4 : 5};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: start;
  padding: 0px 58px;

  @media ${device.desktop} {
    padding: 0px 30px;
  }

  @media ${device.laptopL} {
    padding: 0px 20px;
  }
`

export const ClientName = styled(titleH1)`

`

export const WeddingInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;

  & span:first-child {
    padding-left: 0px;
    border-left: 0;
  }

  & span:last-child {
    padding-right: 0px;
  }

  @media ${device.tablet} {
    display: none;
  }
`

export const TextInfo = styled(body2)`
  padding-left: 25px;
  padding-right: 25px;
  border-left: 1px solid #979797;

  @media ${device.desktop} {
    font-size: 14px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media ${device.laptopL} {
    font-size: 13px;
    padding-left: 15px;
    padding-right: 15px;
  }
`

export const HeaderIcons = styled.div`
  flex: ${({reservePhase}) => reservePhase ? 7 : 6};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  height: 100%;

  @media ${device.laptop} {
    display: none;
  }
`

export const IconContainer = styled.div`
  width: 100%;
  max-width: 170px;
  height: 100%;
  border: 0;
  border-left-width: 1px;
  border-color: ${p => p.theme.menuBorderColor};
  border-style: solid;
  padding: 5px 2px;
  cursor: pointer;
  transition: background-color 1s linear;

  background-color: ${p => p.settingsOpen || p.selected ? p.theme.primaryCTA : 'transparent'};

  &:hover {
    background-color: ${p => p.theme.primaryCTA};
  }
`

export const IconGroup = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
`

export const IconImg = styled.img`
  width: auto;
  height: 35px;

  @media ${device.desktop} {
    height: 25px;
  }

  @media ${device.laptopL} {
    height: 22px;
  }
`

export const IconAgenda = styled.img`
  width: auto;
  height: 20px;
  margin-left: 8px;
`

export const IconText = styled.div`
  margin-top: 7px;
  line-height: 18px;
  font-size: 18px;
  
  @media ${device.desktop} {
    margin-top: 5px;
    font-size: 17px;
    line-height: 17px;
  }
  
  @media ${device.laptopL} {
    font-size: 16px;
    line-height: 16px;
  }
`

/******************************** Styles for MENU MOBILE ********************************/

export const MobileMenuIcon = styled.div`
  display: none;
  flex: 1;
  width: 100%;
  max-width: 50px;
  height: 100%;
  text-align: center;
  padding: 5px;

  @media ${device.laptop} {
    display: block;
  }
`;

export const MenuImg = styled.img`
  height: 100%;
  width: 20px;
  cursor: pointer;
`;

export const MobileMenu = styled.div`
  background-color: #ffffff;
  overflow-y: auto;
  height: calc(100% - 108px);
  position: fixed;
  right: ${({ open }) => (open ? "0px" : "-300px")};
  top: 108px;
  z-index: 1000;
  width: 300px;
  border-left: 1px solid ${p => p.theme.primaryCTA};
  border-bottom: 1px solid ${p => p.theme.primaryCTA};
  transition: right ${mobileMenuTransition} linear;

  @media ${device.minLaptop} {
    right: -300px;
  }

  @media ${device.desktopL} {
    height: calc(100% - 89px);
    top: 89px;
  }

  @media ${device.desktop} {
    height: calc(100% - 80px);
    top: 80px;
  }

  @media ${device.laptopL} {
    height: calc(100% - 66px);
    top: 66px;
  }

  @media ${device.mobileL} {
    height: calc(100% - 60px);
    top: 60px;
    right: ${({ open }) => (open ? "0px" : "-220px")};
    width: 220px;
  }

  @media ${device.mobileS} {
    right: ${({ open }) => (open ? "0px" : "-200px")};
    width: 200px;
  }
`;

export const MobileMenuGroup = styled.div`
  border: 0;
  border-top-width: ${({ border }) => border ? "1px" : "0px"};
  border-color: ${p => p.theme.primaryCTA};
  border-style: ${({ border }) => border ? "solid" : "none"};
`

export const MobileMenuRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;

  @media ${device.tablet} {
    padding: 15px 10px;
  }
`

export const MobileImg = styled.img`
  height: 24px;
  width: 20%;
  cursor: pointer;

  @media ${device.mobileL} {
    height: 18px;
  }
`

export const MobileText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  padding-left: 40px;
  cursor: pointer;
  transition: color 0.5s linear;

  &:hover {
    color: ${p => p.theme.secondaryCTA};
  }

  @media ${device.mobileL} {
    padding-left: 20px;
    font-size: 14px;
  }
`

export const MobileArrow = styled.div`
  cursor: pointer;
  padding-left: 40px;
  
  & svg {
    width: 12px;
    height: 12px;
    transition: transform ${mobileMenuTransition} linear;
    transform: ${p => p.open ? 'rotateZ(90deg)' : 'rotateZ(-90deg)'};

    & #right, & #left {
      fill: ${p => p.theme.primaryCTA};
    }

    &:hover #right, &:hover #left {
      fill: ${p => p.theme.secondaryCTA};
    }
  }
`;

export const MobileMenuCollapse = styled(Collapse)`
  border: 0 !important;
  border-radius: 0 !important;
  border-top-width: ${({ border }) => border ? "1px" : "0px"} !important;
  border-color: ${p => p.theme.primaryCTA} !important;
  border-style: ${({ border }) => border ? "solid" : "none"} !important;
  background-color: transparent !important;
  font-variant: none !important;
  color: inherit !important;
  font-size: inherit !important;
  line-height: inherit !important;
  font-feature-settings: inherit !important;

  & .ant-collapse-header {
    padding: 0 !important;
  }
`

export const MobileMenuPanel = styled(Panel)`
  border-radius: 0 !important;
  border-bottom: 0 !important;

  & .ant-collapse-content {
    color: inherit;
    background-color: transparent;
    border-top: 0 !important;
    
    & .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
`

export const MobileSettingsMenu = styled.div`
  overflow: hidden;
  border: 0;
  border-top-width: ${({ open }) => open ? "1px" : "0px"};
  border-color: ${p => p.theme.primaryCTA};
  border-style: ${({ open }) => open ? "solid" : "none"};
  background-color: ${p => p.theme.secondaryColor};
`;

export const MobileSettingsMenuGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border: 0;
  border-top-width: ${({ border }) => border ? "1px" : "0px"};
  border-color: ${p => p.theme.primaryCTA};
  border-style: ${({ border }) => border ? "solid" : "none"};

  @media ${device.tablet} {
    padding: 15px 10px;
  }
`

export const MobileLanguageImg = styled.img`
  height: 14px;
  width: 20%;
`

export const MobileLanguageSpace = styled.div`
  width: 20%;
`

export const MobileSettingsText = styled.div`
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  padding-left: 40px;
  cursor: pointer;
  transition: color 0.5s linear;

  &:hover {
    color: ${p => p.theme.secondaryCTA};
  }

  @media ${device.mobileL} {
    padding-left: 20px;
    font-size: 13px;
  }
`

/******************************** Styles for SETTINGS MENU ********************************/

export const SettingsMenu = styled.div`
  background-color: ${p => p.theme.secondaryColor};
  overflow-y: auto;
  height: auto;
  max-height: calc(100% - 108px);
  position: fixed;
  right: ${({ open }) => (open ? "0px" : "-300px")};
  top: 108px;
  z-index: 1000;
  width: 300px;
  transition: right ${settingsMenuTransition} linear;
  padding: 20px 40px;

  @media ${device.desktopL} {
    max-height: calc(100% - 89px);
    top: 89px;
    padding: 10px 30px;
  }

  @media ${device.desktop} {
    max-height: calc(100% - 80px);
    top: 80px;
    padding: 10px 20px;
  }

  @media ${device.laptopL} {
    max-height: calc(100% - 66px);
    top: 66px;
  }

  @media ${device.laptop} {
    right: -300px;
  }

  @media ${device.mobileL} {
    max-height: calc(100% - 60px);
    top: 60px;
  } 
`;

export const SettingsMenuGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border: 0;
  border-top-width: ${({ border }) => border ? "1px" : "0px"};
  border-color: ${p => p.theme.primaryBorderColor};
  border-style: ${({ border }) => border ? "solid" : "none"};
`

export const SettingsImg = styled.img`
  height: 24px;
  width: 20%;
  margin-right: 25px;
`

export const LanguageImg = styled.img`
  height: 14px;
  width: 20%;
  margin-right: 25px;
`

export const LanguageSpace = styled.div`
  width: 20%;
  margin-right: 25px;
`

export const SettingsText = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  padding-left: 15px;
  cursor: pointer;
  transition: color 0.5s linear;

  &:hover {
    color: ${p => p.theme.secondaryCTA};
  }
`