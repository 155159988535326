import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, FieldArray, reduxForm, FormSection, formValueSelector } from "redux-form";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import {
  MngGuestAccess,
  NotifyGuest,
  UpdateAboutUs,
  UpdateBillings,
  UpdateProfile,
} from "../../infra/requests/MyWeddingRequests";
import { TransformToFormData } from "../../infra/services/formdata/TransformToFormData";
import { findPageByTag } from "../../infra/services/utils/PageInfo";
import { formatSpace } from "../../infra/services/utils/SpaceName";
import ImageInput from "../../components/inputs/ImageInput";
import SaveButton from "../../components/buttons/SaveButton";
import {
  alternativeFields,
  brideFields,
  groomFields,
  guestFields,
  weddingFields,
} from "./ProfileFormFields";
import {
  Title,
  Subtitle,
  FormContainer,
  SectionTitle,
  ProfileContainer,
  SectionSubtitle,
} from "./ProfileStyles";
import moment from "moment";
import { bindActionCreators } from "redux";
import { SaveWedding } from "../../redux/Wedding/wedding.actions";
import FormValidator from "../../infra/services/validations/FormValidator";
import { SpinLoading } from "../../styles/BasicStyles";
import { AnswerQuestion } from "../../infra/requests/MyWeddingRequests";
import { isValidPhoneNumber } from 'react-phone-number-input';
import MaterialNumberInput from "../../components/inputs/MaterialNumberInput";
import CheckboxInput from "../../components/inputs/CheckboxInput";
import SelectInput from "../../components/inputs/SelectInput";
import { visitedLocals } from "../agenda/ReserveInfoModal";
import SelectLanguageInput from "../../components/inputs/SelectLanguageInput";
import TextInput from "../../components/inputs/TextInput";
import Alerts from "../../components/alert/Alert";
import BaseButton from "../../components/buttons/BaseButton";
import { Icon, Input, Modal, Popconfirm, Tooltip, message } from "antd";
import styled from 'styled-components';
import { InputLabel, MaterialInputSection } from "../../components/inputs/MaterialStyles";
const confirm = Modal.confirm;

export const CodeP = styled.p`
  font-size: 15px; 
  text-align: start;
  margin-bottom: 5px !important;
`;

export const CodeInput = styled.div`
  display: flex;
  margin-bottom: 20px;

  .ant-input {
    border-radius: 4px 0 0 4px;
  }

  .ant-btn {
    border-left: none;
  }

  .ant-btn:not([disabled]):hover {
    text-decoration: none;
    border: 1px solid #c9a947;
  }
`;


const FormValidations = (values) => {
  // console.warn('values', values);
  let errors: any = {};

  const validations = {
    expectedMinPeople: "required|isInteger|minNumber:1",
  }
  errors = FormValidator.make(validations)(values);

  errors.bride = FormValidator.make({
    name: "required",
    contact: "required",
  })(values?.bride);

  errors.groom = FormValidator.make({
    name: "required",
    contact: "required",
  })(values?.groom);

  const guestValidations = {};
  // If exists guest contact or email info, then name is required
  if ((values?.alternativeGuest?.email && values?.alternativeGuest?.email !== '')
    || (values?.alternativeGuest?.contact && values?.alternativeGuest?.contact !== '')) {
    guestValidations['name'] = 'required';
    guestValidations['relationToCouple'] = 'required';
  }
  errors.alternativeGuest = FormValidator.make(guestValidations)(values?.alternativeGuest);

  if (values.bride && values.bride.contact && !isValidPhoneNumber(values.bride.contact)) {
    errors.bride.contact = 'INPUT_ERROR_INVALID_MOBILE_PHONE';
  }

  if (values.groom && values.groom.contact && !isValidPhoneNumber(values.groom.contact)) {
    errors.groom.contact = 'INPUT_ERROR_INVALID_MOBILE_PHONE';
  }

  if (values?.bride?.contact && values?.groom?.contact && values?.bride?.contact.trim() === values?.groom?.contact.trim()) {
    errors.bride.contact = 'REGISTER_ERROR_SAME_CONTACT';
    errors.groom.contact = 'REGISTER_ERROR_SAME_CONTACT';
  }

  if (values?.alternativeGuest?.contact && !isValidPhoneNumber(values?.alternativeGuest?.contact)) {
    errors.alternativeGuest.contact = 'CONTACT_ERROR_INVALID_MOBILE_PHONE';
  }

  if (values?.alternativeGuest?.contact && (
    (values?.bride?.contact && values?.bride?.contact.trim() === values?.alternativeGuest?.contact.trim()) ||
    (values?.groom?.contact && values?.groom?.contact.trim() === values?.alternativeGuest?.contact.trim()))
  ) {
    errors.alternativeGuest.contact = 'ALTERNATIVE_GUEST_ERROR_SAME_EMAIL';
  }

  return errors;
};

class ProfileReserve extends Component {
  state = {
    description: null,
    goToNextPage: false,
    isProcessClosed: undefined,
    ready: false,
    loading: false,
    mngAccess: false,
    blockGuestAccess: false,
    sendingEmail: false,
    weddingAccessCode: ''
  };

  componentDidMount = () => {
    const { initialValues } = this.props;
    document.body.style.overflowY = "auto";
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    this.setState({
      ready: true,
      blockGuestAccess: initialValues.blockGuestAccess || false,
      weddingAccessCode: initialValues.code || '',
    });
  };

  onSubmit = async (values) => {
    const { translate } = this.props;
    try {

      // if (values?.visitedPlace == false && values?.placeToVisit == false && values?.wantToVisitWithoutInfo == false) {
      //   Alerts.new({
      //     type: 'warning',
      //     title: translate('ATTENTION'),
      //     text: translate('VISIT_INFO_WARNING')
      //   });
      //   return;
      // }

      this.setState({ loading: true });

      const { history, dispatch, SaveWedding } = this.props;
      // console.warn('Values', values);

      const payload = TransformToFormData({
        ...values
      });

      if (values.visitedPlace === false) {
        payload.append('visitedPlace', 'false');
      }

      if (values.accessToConfirmedWedding === false) {
        payload.append('accessToConfirmedWedding', 'false');
      }

      let { data, success } = await UpdateProfile(payload);

      if (success) {
        // dispatch(SaveWedding(data));
        Alerts.new({
          type: 'success',
          title: translate('SUCCESS'),
          text: translate('RESERVE_PROFILE_SUCCESS')
        });

        window.location.reload(true);
      }

      this.setState({ loading: false });
    } catch (e) {
      console.error(e);
      Alerts.new({
        type: 'error',
        title: translate('ERROR'),
        text: translate('RESERVE_PROFILE_ERROR')
      });
      this.setState({ loading: false });
    }
  };

  // confirmMngGuestAccess = () => {
  //   const { blockGuestAccess } = this.state;
  //   const { translate } = this.props;
  //   if (blockGuestAccess) {
  //     confirm({
  //       title: translate('ALTERNATIVE_GUEST_NOTIFY_QUESTION'),
  //       content: translate('ALTERNATIVE_GUEST_ACCESS_TEXT'),
  //       okText: translate('UNLOCK_SEND'),
  //       cancelText: translate('UNLOCK'),
  //       onOk: () => {
  //         this.mngGuestAccess(true);
  //       },
  //       onCancel: () => {
  //         this.mngGuestAccess();
  //       },
  //     });
  //   } else this.mngGuestAccess();

  // }

  // mngGuestAccess = async (sendEmail: boolean = false) => {
  //   const { translate } = this.props;
  //   const { history, dispatch, SaveWedding } = this.props;

  //   this.setState({ mngAccess: true });
  //   let { data, success } = await MngGuestAccess({ sendEmail });

  //   if (success && data) {
  //     // dispatch(SaveWedding({...this.props.wedding, wedding: data}));

  //     Alerts.new({
  //       type: 'success',
  //       title: translate('SUCCESS'),
  //       text: data?.blockGuestAccess ? translate('ALTERNATIVE_GUEST_BLOCKED') : translate('ALTERNATIVE_GUEST_UNBLOCKED')
  //     });
  //     this.setState({
  //       mngAccess: false,
  //       blockGuestAccess: data?.blockGuestAccess || false,
  //       weddingAccessCode: data?.code || ''
  //     });
  //     window.location.reload(true);
  //   } else {
  //     Alerts.new({
  //       type: 'error',
  //       title: translate('ERROR'),
  //       text: translate('ALTERNATIVE_GUEST_ERROR_MNG_ACCESS')
  //     });
  //     this.setState({ mngAccess: false });
  //   }
  // }

  // copyCode = () => {
  //   const { translate } = this.props;
  //   const { weddingAccessCode } = this.state;
  //   // Navigator clipboard api needs a secure context (https)
  //   if (navigator.clipboard && window.isSecureContext) {
  //     navigator.clipboard.writeText(weddingAccessCode);
  //   } else {
  //     // Use the 'out of viewport hidden text area' trick
  //     const textArea = document.createElement('textarea');
  //     textArea.value = weddingAccessCode;

  //     // Move textarea out of the viewport so it's not visible
  //     textArea.style.position = 'absolute';
  //     textArea.style.left = '-999999px';

  //     document.body.prepend(textArea);
  //     textArea.select();

  //     document.execCommand('copy');
  //   }
  //   message.success(translate('TEXT_COPIED'));
  // }

  // notifyGuest = (
  //   <div>
  //     <div>Têm a certeza que pretende notificar o convidado?</div>
  //     <div>Irá ser enviado o código de acesso ao casamento e/ou à informação do casal ao convidado.</div>
  //   </div>
  // );

  // notifyGuestCode = async () => {
  //   const { translate } = this.props;

  //   this.setState({ sendingEmail: true });
  //   let { data, success } = await NotifyGuest();

  //   if (success && data) {
  //     Alerts.new({
  //       type: 'success',
  //       title: translate('SUCCESS'),
  //       text: translate('ALTERNATIVE_GUEST_NOTIFIED')
  //     });
  //     this.setState({ sendingEmail: false });
  //   } else {
  //     Alerts.new({
  //       type: 'error',
  //       title: translate('ERROR'),
  //       text: translate('GENERIC_ERROR')
  //     });
  //     this.setState({ sendingEmail: false });
  //   }
  // }

  render() {
    const { translate, activeLanguage, history, visitedPlace, expectedMinPeople } = this.props;
    const { initialValues, info, user } = this.props;
    const { handleSubmit, submitFailed, invalid, dirty, submitting } = this.props;
    const { ready, loading, mngAccess, blockGuestAccess, weddingAccessCode, sendingEmail } = this.state;

    const BlockAccess = (
      <div>
        <div>{translate('ALTERNATIVE_GUEST_BLOCK_ACCESS_QUESTION')}</div>
        <div>{translate('ALTERNATIVE_GUEST_BLOCK_ACCESS_INFO')}</div>
      </div>
    );

    const UnblockAccess = (
      <div>
        <div>{translate('ALTERNATIVE_GUEST_UNBLOCK_ACCESS_QUESTION')}</div>
        <div>{translate('ALTERNATIVE_GUEST_UNBLOCK_ACCESS_INFO')}</div>
      </div>
    );

    if (!ready) return <SpinLoading />;

    return (
      <ProfileContainer>
        <Title>{translate("ABOUT_US")}</Title>
        <FormContainer onSubmit={handleSubmit(this.onSubmit)}>
          <FormSection name="">
            <SectionTitle>{translate("COUPLE_PICTURE")}</SectionTitle>
            <Field
              component={ImageInput}
              accept="image/jpeg, image/png"
              name={"photo"}
              label={translate("UPLOAD_YOUR_FILE")}
              hasCrop={true}
              ratio={1.0}
            />
          </FormSection>
          <FormSection name="">
            <SectionTitle>{translate("WEDDING_INFO")}</SectionTitle>
            <Field
              component={SelectLanguageInput}
              name={'language'}
              type={'select'}
              label={translate('COMMUNICATION_LANGUAGE')}
            />
            <Field
              component={MaterialNumberInput}
              label={translate('EXPECTED_MIN_PEOPLE')}
              name={`expectedMinPeople`}
              step={1}
              min={1}
              translate={translate}
              labelFixed={true}
            />
            {expectedMinPeople && expectedMinPeople !== '' && expectedMinPeople < 140 &&
              <p style={{ textAlign: 'left' }}>{translate('WARNING_MIN_PEOPLE')}</p>
            }
          </FormSection>
          <FormSection name="bride">
            <SectionTitle>{translate("BRIDE_INFO")}</SectionTitle>
            {brideFields.map((field) => (
              <Field
                key={field.name}
                component={field.component}
                name={field.name}
                type={field.type}
                disabled={field.disabled}
                label={
                  field.asterisc
                    ? `${translate(field.label)} *`
                    : translate(field.label)
                }
              />
            ))}
          </FormSection>
          <FormSection name="groom">
            <SectionTitle>{translate("GROOM_INFO")}</SectionTitle>
            {groomFields.map((field) => (
              <Field
                key={field.name}
                component={field.component}
                name={field.name}
                type={field.type}
                disabled={field.disabled}
                label={
                  field.asterisc
                    ? `${translate(field.label)} *`
                    : translate(field.label)
                }
              />
            ))}
          </FormSection>

          {initialValues?.alternativeGuest?.email && initialValues?.alternativeGuest?.email !== '' && <React.Fragment>
            <FormSection name="alternativeGuest">
              <SectionTitle>{translate("ALTERNATIVE_GUEST_INFO")}</SectionTitle>
              {guestFields.map((field) => (
                <Field
                  key={field.name}
                  component={field.component}
                  name={field.name}
                  type={field.type}
                  disabled={field.disabled}
                  label={
                    field.asterisc
                      ? `${translate(field.label)} *`
                      : translate(field.label)
                  }
                />
              ))}
              <Field
                  component={SelectInput}
                  name={'relationToCouple'}
                  type={'select'}
                  disabled={false}
                  label={translate('ALTERNATIVE_GUEST_RELATION_COUPLE')}
                  dataKey={'value'}
                  dataLabel={'name'}
                  data={[
                    {value: 'TU', name: translate('THIRD_USER_OPTION')},
                    {value: 'FF', name: translate('FRIEND_FAMILY_OPTION')},
                    {value: 'WP', name: translate('WEDDING_PLANNER_OPTION')},
                  ]}
                />
            </FormSection>
            {/* {!user?.alternativeUser && <FormSection name='' style={{ textAlign: 'initial' }}>
              <!-- <Field
                component={CheckboxInput}
                name={'accessToConfirmedWedding'}
                label={translate('ALTERNATIVE_GUEST_ACCESS_WEDDING')}
              /> -->

              <SectionTitle>{translate("ALTERNATIVE_GUEST_ACCESS_INFO")}</SectionTitle>
              <SectionSubtitle>{translate("ALTERNATIVE_GUEST_ACCESS_SUBTITLE_RESERVE")}</SectionSubtitle>
              <Popconfirm
                title={blockGuestAccess ? UnblockAccess : BlockAccess}
                onConfirm={() => this.confirmMngGuestAccess()}
                okType={blockGuestAccess ? 'primary' : 'danger'}
                okText={blockGuestAccess ? 'Desbloquear' : 'Bloquear'}
                cancelText={translate('CANCEL')}>
                <BaseButton
                  align="initial"
                  variant="raised"
                  htmlType="button"
                  type={blockGuestAccess ? 'primary' : 'danger'}
                  loading={mngAccess}
                  style={{ marginTop: 15 }}
                  text={blockGuestAccess ? translate('ALTERNATIVE_GUEST_UNBLOCK_ACCESS') : translate('ALTERNATIVE_GUEST_BLOCK_ACCESS')}
                />
              </Popconfirm>

              {!blockGuestAccess && <MaterialInputSection>
                <InputLabel>{translate('WEDDING_CODE')}</InputLabel>
                <CodeInput>
                  <Input value={weddingAccessCode} disabled={true} />
                  <Tooltip title={translate('COPY_CODE')}>
                    <BaseButton
                      type='default'
                      icon='copy'
                      style={{ borderRadius: 0 }}
                      onClick={this.copyCode}
                    />
                  </Tooltip>
                  <Popconfirm
                    title={this.notifyGuest}
                    onConfirm={this.notifyGuestCode}
                    okText={translate('NOTIFY')}
                    cancelText={translate('CANCEL')}>
                    <BaseButton
                      type='default'
                      icon={sendingEmail ? 'loading' : 'mail'}
                      style={{ borderRadius: '0 4px 4px 0' }}
                    />
                  </Popconfirm>
                </CodeInput>
              </MaterialInputSection>}
            </FormSection>} */}
          </React.Fragment>}

          <SaveButton
            htmlType="submit"
            error={submitFailed && invalid}
            text={translate("SAVE")}
            textMobile={translate("SAVE")}
            // onClick={() => this.setState({ goToNextPage: true })}
            loading={loading}
          />
        </FormContainer>
      </ProfileContainer>
    );
  }
}

const selector = formValueSelector('profile_form');

ProfileReserve = reduxForm({
  form: "profile_form",
  validate: FormValidations,
})(ProfileReserve);

const mapStateToProps = (state) => ({
  initialValues: state.wedding.wedding,
  info: state.info,
  user: state.user,
  expectedMinPeople: selector(state, 'expectedMinPeople'),
  visitedPlace: selector(state, 'visitedPlace'),
  accessToConfirmedWedding: selector(state, 'accessToConfirmedWedding'),
});

const mapActionToProps = (dispatch) =>
  bindActionCreators({ SaveWedding }, dispatch);

export default withLocalize(
  withRouter(connect(mapStateToProps, mapActionToProps)(ProfileReserve))
);
