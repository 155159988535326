import styled, { createGlobalStyle } from "styled-components";
import { Spin, Form as AntForm } from "antd";
import { StyledButton } from "../components/buttons/ButtonsStyles";
import { device, size } from "./Responsive";

export const BasicStyles = createGlobalStyle`

  @import url('https://fonts.googleapis.com/css?family=Abel|Titillium+Web:200,200i,300,300i,400,400i,600,600i,700,700i,900&display=swap');

  @import 'cropperjs/dist/cropper.css';

  *:focus {
    outline: none;
  }

  body, button {
    font-family: 'Abel', 'sans-serif' !important;
    overflow-x: hidden;
  }

  body {
    color: #000000;
    font-size: 20px;
    line-height: 31px;
    font-weight: 400;

    @media ${device.laptop} {
      font-size: 14px;
      line-height: 20px;
    }
  }

  p, ol, ul, dl {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Titillium Web', sans-serif;
  }

  .ant-layout {
    background: #fff !important;
  }

  html {
    scroll-behavior: smooth;
  }

  textarea.ant-input {
    font-size: 16px;
  }

  .ant-select-dropdown {
    & .ant-select-dropdown-menu-item {
      padding: 0;

      &:hover {
        background-color: #FAF9F9 !important;
      }
    }

    & .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
      background-color: #FAF9F9 !important;
    }
  }

  & .ant-select-selection-selected-value {
    & > div {
      padding: 0;
    }
  }

  .ant-notification {
    &&& {
      top: 118px !important;
    }
  }

  .ant-notification-notice {
    &&& {
      border-radius: 12px;
    }
  }

  .ant-notification-notice-message {
    &&& {
      color: #000000;
      font-family: 'Titillium Web', sans-serif;
      font-size: 24px;
      font-weight: 300;
      line-height: 29px;
    }
  }

  .ant-notification-notice-description {
    &&& {
      color: #000000;
      font-size: 20px;
      font-weight: 400;
      line-height: 31px;
    }
  }

  .successAlert {
    &&& {
      background-color: #DECBB3;
    }
  }

  .infoAlert {
    &&& {
      background-color: #e6f7ff;
    }
  }

  .warningAlert {
    &&& {
      background-color: #fffbe6;
    }
  }

  .errorTimeAlert {
    &&& {
      background-color: #f8bbd0;
    }
  }

  .errorAlert {
    &&& {
      background-color: #DE8F8F;
    }
  }

  @media ${device.laptop} {
    .ant-notification-notice-message {
      &&& {
        font-size: 20px;
        line-height: 25px;
      }
    }

    .ant-notification-notice-description {
      &&& {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  @media ${device.tablet} {
    .ant-notification {
      &&& {
        top: 90px !important;
        margin-right: 10px;
        margin-left: 10px;
      }
    }

    .ant-notification-notice {
      &&& {
        padding: 10px 14px;
      }
    }

    .ant-notification-notice-message {
      &&& {
        font-size: 16px;
        line-height: 22px;
      }
    }

    .ant-notification-notice-description {
      &&& {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .customSelect {
    height: 40px;

    & .ant-select-selection--single {
      height: 40px;

      & .ant-select-selection__rendered {
        line-height: 40px;
        height: 40px;

        & .ant-select-selection-selected-value {
          line-height: 40px;
          height: 40px;
          padding-right: 30px;

          & > div {
            height: 40px;
            padding-left: 0px;
            background-color: transparent;
            border-top: 0;
          }

          & .customSelectBox {
            display: none;
          }
        }

        & .ant-select-selection__placeholder {
          height: 40px !important;
          line-height: 40px !important;
          opacity: 0.3 !important;

          @media ${device.tablet} {
            font-size: 16px !important;
          }
        }
      }
    }
  }

  .recharts-tooltip-wrapper {
    z-index: 999;
  }

  .ant-tooltip-inner {
    background-color: rgb(0 0 0 / 80%);

    @media (max-width: ${size.laptopS}) {
      .mobile {
        display: block;
      }
  
      .desktop {
        display: none;
      }
    }
  
    @media (min-width: 901px) {
      .mobile {
        display: none !important;
      }
  
      .desktop {
        display: block !important;
      }
    }
  }

  .ant-radio-button-wrapper-checked {
    color: #fff !important;
    background: #bc9222 !important;
  }

  .ant-popover {
    z-index: 99999999999999 !important;
  }

  .ant-checkbox-wrapper {
    display: flex !important;
    align-content: center;
    align-items: center;
  }

  .policy-modal .ant-modal-body {
    padding: 0 !important;
  }

  .change-guests-modal .ant-modal-confirm-content {
    margin-left: 0 !important;
  }

  * {
    scrollbar-width: thin;
    scrollbar-height: thin;
    scrollbar-color: #8f8e8e #e5e5e5;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #e5e5e5; 
  }

  ::-webkit-scrollbar-thumb {
    background: #8f8e8e; 
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #8f8e8e; 
  }
`;

export const HeaderContainer = styled.div`
  width: calc(100% - 80px);
  display: flex;
  position: fixed;
  padding: 20px;
  line-height: 1;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
  z-index: 50;
  border-bottom: 2px solid ${({ theme }) => theme.secondaryBackgroundColor};
  align-items: center;
`;

export const HeaderTitle = styled.div`
  width: calc(
    100% - ${({ buttons }) => (buttons ? buttons + " * 170px" : "0px")}
  );
  display: inline-block;
  text-align: left;
`;

export const HeaderButtonsContainer = styled.div`
  width: calc(${({ buttons }) => (buttons ? buttons + " * 170px" : "0px")});
  float: right;

  ${StyledButton} {
    margin-left: 10px;
    float: right;
  }
`;

export const PageTitle = styled.h1`
  font-size: ${({ theme }) => theme.titleSize};
  color: ${({ theme }) => theme.primaryColor};
  margin-bottom: 5px;
`;

export const SectionTitle = styled.h2`
  font-size: ${({ theme }) => theme.sectionSize};
  color: ${({ theme }) => theme.thirdColor};
  margin-bottom: 10px;
  text-align: left;
  margin-top: ${({ subsection }) => (subsection ? "50px" : 0)};
`;

export const PageContainer = styled.div`
  width: 100%;
  margin-top: 105px;
  position: relative;
  display: inline-block;
  padding: 20px;
  line-height: 1;
  background-color: ${({ theme }) => theme.primaryBackgroundColor};
`;

// margin: 0 10px;
export const TableButton = styled.div`
  display: inline-block;
  z-index: 999;
  padding: 5px 8px;

  &:hover {
    color: ${({ theme, primary, error }) =>
    primary ? theme.primaryColor : error ? theme.inputErrorColor : "inherit"};
  }

  .anticon {
    margin-right: 5px;
  }
`;

export const SpinLoading = styled(Spin)`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 50px !important;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const FormContainer = styled.div`
  width: calc(100% - 20px);
  max-width: ${({ singleColumn }) => (singleColumn ? "600px" : "100%")};
  margin-top: 105px;
  text-align: left;
  position: relative;
  display: inline-block;
  padding: 20px 8px;
  line-height: 1;
  background-color: ${p => p.theme.primaryBackgroundColor};
  text-align: left;
`;

export const BaseForm = styled(AntForm)`
  display: inline-block;
  width: 100%;
`;

export const TableFilterSection = styled.div`
  display: inline-block;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
`;

export const TableImage = styled.div`
  display: inline-block;
  width: 200px;
`;

export const DefaultLanguageTab = styled.div`
  border-bottom: 1px solid rgb(232, 232, 232);
  padding: 12px 16px;
  margin-top: -2px;
  margin-bottom: 16px;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 990px;
  flex-direction: column;
  padding: 80px 20px;
  margin: auto;
  margin-bottom: 40px;
  position: relative;

  @media ${device.desktopL} {
    padding: 60px 20px;
  }

  @media ${device.laptop} {
    padding: 50px 20px;
  }

  @media ${device.tablet} {
    padding: 40px 20px;
  }

  @media ${device.mobileL} {
    padding: 30px 10px;
    margin-bottom: 60px;
  }
`;
