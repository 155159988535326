import { InputNumber } from "antd";
import styled from "styled-components";
import { device } from "../../styles/Responsive";

export const MaterialInputSection = styled.div`
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: ${({ noLabel }) => noLabel ? '0' : '30px'};
  background: transparent;
  text-align: start;

  @media ${device.tablet} {
    margin-top: ${({ noLabel }) => noLabel ? '0' : '20px'}
  }
`;

export const MaterialLabel = styled.div`
  display: inline-block;
  position: absolute;
  font-weight: 400;
  font-size: ${({ filled }) => (filled ? "16px" : "20px")};
  top: ${({ filled }) => (filled ? "-8px" : "10px")};
  left: 0;
  transition: all 0.2s ease;
  transition: color 0;
  color: black;
  opacity: ${p => p.tag === 'GUESTS' ? 1 : (p.filled ? 0.2 : 0.3)};
  pointer-events: none;
  text-align: left;

  @media ${device.laptop} {
    font-size: ${({ filled }) => (filled ? "14px" : "18px")};
  }

  @media ${device.tablet} {
    font-size: ${({ filled }) => (filled ? "14px" : "16px")};
  }
`;

export const MaterialLabelFixed = styled.div`
  text-align: left;
  font-weight: 400;
  font-size: ${({ filled }) => (filled ? "16px" : "20px")};
  transition: all 0.2s ease;
  transition: color 0;
  color: black;
  opacity: ${p => p.tag === 'GUESTS' ? 1 : (p.filled ? 0.2 : 0.3)};
  pointer-events: none;

  @media ${device.laptop} {
    font-size: ${({ filled }) => (filled ? "14px" : "18px")};
  }

  @media ${device.tablet} {
    font-size: ${({ filled }) => (filled ? "14px" : "16px")};
  }
`;

export const InputLabel = styled.div`
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  transition: all 0.2s ease;
  transition: color 0;
  color: black;
  opacity: 0.2;
  pointer-events: none;

  @media ${device.laptop} {
    font-size: 14px;
  }

  @media ${device.tablet} {
    font-size: 14px;
  }
`;

export const MaterialErrorLabel = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  top: ${({ filled }) => (filled ? "0" : "10px")};
  left: 0;
  transition: all 0.2s ease;
  transition: color 0;
  color: #de8f8f;
  pointer-events: none;
  text-align: left;
`;

export const MaterialCheckboxLabel = styled.div`
  display: inline-block;
  font-weight: 400;
  line-height: 24px;
  font-size: ${({ filled }) => (filled ? "16px" : "20px")};
  top: ${({ filled }) => (filled ? "0" : "10px")};
  left: 0;
  transition: all 0.2s ease;
  transition: color 0;
  color: ${({ showError }) => (showError ? "#de8f8f" : "black")};
  margin-left: 30px;
  text-decoration: ${({ clickable }) => (clickable ? "underline" : "none")};
`;

export const MaterialInput = styled.input`
  display: inline-block;
  width: 100%;
  border: none;
  outline: none;
  border-bottom: 1px solid ${({ theme, showError }) => (showError ? "red" : theme.inputBorderColor)};
  margin-top: ${({ noLabel, labelFixed }) => noLabel ? '0' : labelFixed ? '5px' : '16px'};
  font-size: ${({ small }) => small ? '16px' : '20px'};
  line-height: 29px;
  background: transparent;
  opacity: ${p => p?.tag === 'GUESTS' ? 1 : ((p?.disabled || p?.readOnly) ? 0.6 : 1)};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &::placeholder {
    color: #000000;
    opacity: 0.3;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
  }

  @media ${device.laptop} {
    font-size: ${({ small }) => small ? '16px' : '18px'};
  }

  @media ${device.tablet} {
    font-size: 16px;
  }
`;

export const MaterialIcon = styled.span`
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 18px;
  cursor: pointer;
`;

export const MaterialInputNumber = styled(InputNumber)`
  display: inline-block;
  width: 100%;
  border: none;
  outline: none;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid ${({ theme, showError }) => (showError ? theme.inputErrorColor : theme.inputBorderColor)} !important;
  margin-top: ${({ labelFixed }) => labelFixed ? '5px' : '16px'};
  font-size: 20px !important;
  background: transparent;
  opacity: ${p => p.tag === 'GUESTS' ? 1 : (p.disabled ? 0.6 : 1)};
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  &::placeholder {
    color: #000000;
    opacity: 0.3;
  }

  &:hover,
  &:active,
  &:focus {
    outline: none;
    border-color: ${({ theme }) => (theme.inputBorderColor)} !important;
    box-shadow: none !important;
  }

  .ant-input-number-handler-wrap {
    border-left: none !important;
    border-radius: none !important;
  }

  @media ${device.laptop} {
    font-size: 18px;
  }

  @media ${device.tablet} {
    font-size: 16px;
  }
`;