import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import { Spin } from "antd";
import {
  SelectDiv,
  StyledSelectInput,
  StyledOption,
  SelectArrow,
  CloseArrow,
  ArrowImg,
  StyledOptionContent,
  SelectLabelInput
} from "./InputStyles";
import ArrowDown from "../../assets/icons/arrow_down.svg";
import Close from "../../assets/icons/close.svg";

const filterOption = (inputValue, option) => {
  if (option?.props?.children)
    return (
      option?.props?.children.toLowerCase().indexOf(inputValue.toLowerCase()) >
      -1
    );
  return option.key.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
};

const SelectInput = ({
  input,
  data,
  dataKey,
  dataLabel,
  placeholder,
  mode,
  notFoundMessage,
  fetching,
  label,
  disabled,
  fetchData,
  loading,
  meta,
  translatable,
  activeLanguage,
  allowClear,
  removeSelection,
  tag,
  costumeLabel,
  smallIcon = false
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const formatValue = value => {
    if (!value) return undefined;
    if (Array.isArray(value)) return value;
    return value.toString();
  };

  const changeSelect = value => {
    input.onChange(value || '');
  };

  return (
    <SelectDiv>
      {label && <SelectLabelInput>{label}</SelectLabelInput>}
      <StyledSelectInput
        disabled={disabled}
        mode={mode}
        placeholder={placeholder}
        notFoundContent={fetching ? <Spin size="small" /> : notFoundMessage}
        filterOption={filterOption}
        allowClear={allowClear}
        onSearch={fetchData}
        onChange={changeSelect}
        value={formatValue(input?.value)}
        showArrow={false}
        showSearch={false}
        loading={loading}
        error={showError ? 1 : 0}
      >
        {data.map((elem, index) => (
          <StyledOption key={dataKey ? elem[dataKey] : index} disabled={elem.disabled}>
            <StyledOptionContent>
              {costumeLabel
                ? costumeLabel(elem)
                : dataLabel
                  ? (translatable ? elem[dataLabel][activeLanguage.code] : elem[dataLabel])
                  : elem}
            </StyledOptionContent>
          </StyledOption>
        ))}
      </StyledSelectInput>
      {
        allowClear && input?.value ?
          <CloseArrow smallIcon={smallIcon} onClick={() => removeSelection(tag)}><ArrowImg src={Close} /></CloseArrow>
          :
          <SelectArrow smallIcon={smallIcon}><ArrowImg src={ArrowDown} /></SelectArrow>
      }
    </SelectDiv>
  );
};

SelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  fetchData: PropTypes.func,
  loading: PropTypes.bool,
  onPressEnter: PropTypes.func,
  allowClear: PropTypes.bool,
  removeSelection: PropTypes.func,
  tag: PropTypes.string
};

SelectInput.defaultProps = {
  data: [],
  allowClear: false,
};

export default withLocalize(SelectInput);
