import React from "react";
import PropTypes from "prop-types";
import { withLocalize } from "react-localize-redux";
import {
  SelectDiv,
  StyledSelectInput,
  SelectArrow,
  ArrowImg,
  OptionContainer,
  OptionContent,
  Box,
  OptionText,
  SelectLabelInput
} from "./InputStyles";
import ArrowDown from "../../assets/icons/arrow_down.svg";

const CustomSelectInput = ({
  input,
  placeholder,
  disabled,
  meta,
  languages,
  translate,
  dataKey,
  dataLabel,
  label
}) => {
  const { invalid, submitFailed } = meta;
  const showError = invalid && submitFailed;

  const changeSelect = value => {
    input.onChange(value || "");
  };

  return (
    <SelectDiv>
      <SelectLabelInput>{label}</SelectLabelInput>
      <StyledSelectInput
        disabled={disabled}
        placeholder={placeholder}
        allowClear={false}
        onChange={changeSelect}
        value={input?.value || undefined}
        showArrow={false}
        showSearch={false}
        error={showError ? 1 : 0}
        className="customSelect"
      >
        {languages.map((elem, index) => (
          <OptionContainer key={elem[dataKey]}>
            <OptionContent selected={elem.code === input?.value}>
              <Box
                className="customSelectBox"
                selected={elem.code === input?.value}
              />
              <OptionText selected={elem.code === input?.value}>
                {translate(`LANG_${elem.code.toUpperCase()}`)}
              </OptionText>
            </OptionContent>
          </OptionContainer>
        ))}
      </StyledSelectInput>
      <SelectArrow className="customSelectArrow">
        <ArrowImg src={ArrowDown} />
      </SelectArrow>
    </SelectDiv>
  );
};

CustomSelectInput.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  dataKey: PropTypes.string,
  dataLabel: PropTypes.string,
  placeholder: PropTypes.string
};

CustomSelectInput.defaultProps = {
  data: [],
  allowClear: false,
  dataKey: "code",
  dataLabel: "name"
};

export default withLocalize(CustomSelectInput);
